<template>
  <v-row class="upsell-page">
    <!-- Config layout -->
    <MenuApps :menu="menu" :defaultActiveMenu="defaultActiveMenu" />
    <v-col class="upsell-page-container">
      <div class="upsell-page-content">
        <router-view />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import MenuApps from '../components/MenuApps';

export default {
  components: {
    MenuApps,
  },
  data() {
    return {
      menu: [
        {
          title: 'Store Review',
          icon: 'fas fa-home',
          name: 'website.apps.product-review',
          children: [
            {
              title: 'All reviews',
              name: 'website.apps.product-review.list',
            },
            {
              title: 'Imports',
              name: 'website.apps.product-review.imports',
            },
            // {
            //   title: 'Product groups',
            //   name: 'website.apps.product-review.bulk',
            // },
            // {
            //   title: 'Customization',
            //   name: 'website.apps.product-review.custome',
            // },
            // {
            //   title: 'Settings',
            //   name: 'website.apps.product-review.settings',
            // },
          ],
        },
        // {
        //   title: 'Product Review',
        //   icon: 'fas fa-home',
        //   name: 'website.apps.product-review',
        //   children: [
        //     {
        //       title: 'All reviews',
        //       name: 'website.apps.product-review.listProduct',
        //     },
        //     {
        //       title: 'Imports',
        //       name: 'website.apps.product-review.importsProduct',
        //     },
        //   ],
        // },
      ],
      defaultActiveMenu: 'website.apps.product-review.list',
    };
  },
};
</script>

<style lang="scss">
.upsell-page {
  height: 100% !important;
  .upsell-page-container {
    @include flex(center, flex-center);
    overflow-y: auto;
    padding-left: 260px;
    & .upsell-page-content {
      max-width: 1024px;
      min-width: 700px;
      margin-top: 40px;
    }
  }
}
</style>
